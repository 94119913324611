$small-phone:        20.5em;  //325px
$phone:              27em;    //500px
$tablet:             48em;    //768px
$tablet-wide:        64em;    //1024px
$desktop:            81.25em; //1300px
$desktop-medium:     100em;   //1600px
$desktop-large:      112.5em; //1800px
$desktop-xlarge:     125.0em; //2000px
$max-width:          93.75em; //1500px
$max-width-text:     64em;    //1500px

@mixin small-phone {
  @media (max-width: #{$small-phone}) {
    @content;
  }
}
@mixin phone {
  @media (max-width: #{$tablet-wide}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin tablet-wide {
  @media (min-width: #{$tablet-wide}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$desktop-medium}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$desktop-large}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$desktop-xlarge}) {
    @content;
  }
}
@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
@mixin edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

/* Colors */

$black: #000;
$white: #fff;
$blue: #214e8c;
$darkblue: #004d9f;
$lightblue: #4d98e8;
$teal: #dcfdfa;
$red: #d20e1c;
$pink: #febdfd;

$purple: #500d76;
$yellow: #fdcf09;
$orange: #fa4d09;
$green: #60a511;
$darkgray: #444;
$gray: #999;
$lightgray: #eee;
$lightestgray: #f9f9f9;
$graybg: #ccc;


$primary: $blue;
$secondary: $lightblue;
$text: $black;

$body: 'Cabin', sans-serif;
$headline: 'Red Hat Display', sans-serif;
$nav: museo-sans, sans-serif;

$basic: all .2s ease-in-out;
$bounce: all .6s cubic-bezier(.5,1.65,.4,.8);
$bounce-curve: cubic-bezier(.5,1.65,.4,.8);

$fast: all .35s cubic-bezier(.57,.2,.21,.89);
$slow: all .5s cubic-bezier(.57,.2,.21,.89);
$slow-curve: cubic-bezier(.57,.2,.21,.89);
$veryslow: all 1s cubic-bezier(.57,.2,.21,.89);

$shadow: 0 .5rem 1.25rem 0 rgba(0,0,0,.2);
$shadow-hover: 0 .5rem 1.5rem 0 rgba(0,0,0,.3);

$button-shadow: 0px .2rem .4rem 0 rgba(0,0,0,.4);
$button-shadow-hover: 0px .3rem .6rem 0 rgba(0,0,0,.6);
$button-shadow-active: inset 0 .5rem 1rem 0 rgba(0,0,0,.3);


%wrap {
  width: 100%;
  max-width: $desktop;
  margin: 0 auto;
  position: relative;
}
%block-padding {
   padding: 1rem 1.5rem;
	@include tablet { 
		padding: 2rem 2rem;		
	}
	@include desktop {
		padding: 4rem 2rem;		
	}
	@include md { 
		padding: 5rem 2rem;				
	}	
	@include lg { 
		padding: 6rem 2rem;						
	}
	@include xl { 
		padding: 7rem 2rem;								
	}		
}
%block-padding-small {
	padding: 1.5rem;
	@include tablet { 
		padding: 1.5rem 1.5rem;		
	}
	@include desktop {
		padding: 2rem 2rem;		
	}
	@include md { 
		padding: 3rem 2rem;				
	}	
	@include lg { 
		padding: 4rem 2rem;						
	}
	@include xl { 
		padding: 4.5rem 2rem;								
	}		
}
%block-padding-top {
	padding-top: 1.5rem;
	@include tablet { 
		padding-top: 2rem;		
	}
	@include desktop {
		padding-top: 4rem;		
	}
	@include md { 
		padding-top: 5rem;				
	}	
	@include lg { 
		padding-top: 6rem;						
	}
	@include xl { 
		padding-top: 7rem;								
	}		
}
%block-padding-bottom {
	padding-bottom: 1.5rem;
	@include tablet { 
		padding-bottom: 2rem;		
	}
	@include desktop {
		padding-bottom: 4rem;		
	}
	@include md { 
		padding-bottom: 5rem;				
	}	
	@include lg { 
		padding-bottom: 6rem;						
	}
	@include xl { 
		padding-bottom: 7rem;								
	}		
}
%block-padding-top-small {
	padding-top: 1.5rem;
	@include tablet { 
		padding-top: 1.5rem;		
	}
	@include desktop {
		padding-top: 2rem;		
	}
	@include md { 
		padding-top: 3rem;				
	}	
	@include lg { 
		padding-top: 4rem;						
	}
	@include xl { 
		padding-top: 4.5rem;								
	}		
}
%block-padding-bottom-small {
	padding-bottom: 1.5rem;
	@include tablet { 
		padding-bottom: 1.5rem;		
	}
	@include desktop {
		padding-bottom: 2rem;		
	}
	@include md { 
		padding-bottom: 3rem;				
	}	
	@include lg { 
		padding-bottom: 4rem;						
	}
	@include xl { 
		padding-bottom: 4.5rem;								
	}		
}
%block-margin-top {
	margin-top: 1.5rem;
	@include tablet { 
		margin-top: 2rem;		
	}
	@include desktop {
		margin-top: 4rem;		
	}
	@include md { 
		margin-top: 5rem;				
	}	
	@include lg { 
		margin-top: 6rem;						
	}
	@include xl { 
		margin-top: 7rem;								
	}		
}
%block-margin-bottom {
	margin-bottom: 1.5rem;
	@include tablet { 
		margin-bottom: 2rem;		
	}
	@include desktop {
		margin-bottom: 4rem;		
	}
	@include md { 
		margin-bottom: 5rem;				
	}	
	@include lg { 
		margin-bottom: 6rem;						
	}
	@include xl { 
		margin-bottom: 7rem;								
	}		
}
%block-margin-top-small {
	margin-top: 1.5rem;
	@include tablet { 
		margin-top: 1.5rem;		
	}
	@include desktop {
		margin-top: 2rem;		
	}
	@include md { 
		margin-top: 3rem;				
	}	
	@include lg { 
		margin-top: 4rem;						
	}
	@include xl { 
		margin-top: 4.5rem;								
	}		
}
%block-margin-bottom-small {
	margin-bottom: 1.5rem;
	@include tablet { 
		margin-bottom: 1.5rem;		
	}
	@include desktop {
		margin-bottom: 2rem;		
	}
	@include md { 
		margin-bottom: 3rem;				
	}	
	@include lg { 
		margin-bottom: 4rem;						
	}
	@include xl { 
		margin-bottom: 4.5rem;								
	}		
}
%block-margin-sides {
   margin-left: 1.5rem;
   margin-right: 1.5rem;
   @include desktop {
      margin-left: 0;
      margin-right: 0;
   }
}
%basic-text {
	line-height: 1.5;
	font-size: 1.1rem;
	text-align: left;
  p {
    margin-bottom: 1rem;
    &last-of-type {
      margin-bottom: 0;
    }
  }
	@include tablet {
    p {
			margin-bottom: 1.5rem;
    }
	}
	@include desktop {
		font-size: 1.3rem;
    p {
			margin-bottom: 1.4rem;
    }
	}
	@include md {
    p {
			margin-bottom: 1.6rem;
    }
	}
	@include lg {
    p {
			margin-bottom: 1.8rem;
    }
	}
}
%headline1 {
  font-family: $headline;
	font-size: 2.5rem;
	@include tablet {
		font-size: 2.8rem;
	}
	@include desktop {
		font-size: 3rem;
	}
	@include md {
		font-size: 3.5rem;
	}
	@include lg {
		font-size: 4em;
	}
}
%headline2 {
  font-family: $headline;
	font-size: 1.5rem;
	line-height: 1.1;
	@include tablet {
		font-size: 1.8rem;
	}
	@include desktop {
		font-size: 2.1rem;
	}
	@include md {
		font-size: 2.4rem;
	}
	@include lg {
		font-size: 2.7rem;
	}
}
%headline3 {
  font-family: $headline;
	font-size: 1.5rem;
	@include tablet {
		font-size: 1.6rem;
	}
	@include desktop {
		font-size: 1.7rem;
	}
	@include md {
		font-size: 1.9rem;
	}
	@include lg {
		font-size: 2.3rem;
	}
}
%headline4 {
  font-family: $headline;
	font-size: 1.25rem;
	@include tablet {
		font-size: 1.35rem;
	}
	@include desktop {
		font-size: 1.45rem;
	}
	@include md {
		font-size: 1.55rem;
	}
	@include lg {
		font-size: 1.9rem;
	}
}
%headline5 {
  font-family: $headline;
	font-size: 1.25rem;
	@include desktop {
		font-size: 1.35rem;
	}
	@include md {
		font-size: 1.4rem;
	}
	@include lg {
		font-size: 1.5rem;
	}
}
%headline6 {
  font-family: $headline;
	font-size: 1rem;
	@include tablet {
		font-size: 1.1rem;
	}
	@include desktop {
		font-size: 1.15rem;
	}
	@include md {
		font-size: 1.2rem;
	}
	@include lg {
		font-size: 1.25rem;
	}
}
%headline7 {
  font-family: $headline;
	font-size: .9rem;
	@include tablet {
		font-size: 1rem;
	}
	@include desktop {
		font-size: .95rem;
	}
	@include md {
		font-size: 1rem;
	}
	@include lg {
		font-size: 1.1rem;
	}
}
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
@mixin aspect($x,$y) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
  	padding-top: $padding;
}
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}
@mixin screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	word-wrap: normal !important;
	&:focus {
		background: $secondary;
		color: $white;
		clip: auto !important;
		display: inline-block;
		font-size: 1rem;
		height: auto;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 100000;
		line-height: normal;
		padding: .5rem;
		width: auto;
	}
}
%clearfix {
  &::before,
  &::after {
    display: table;
    content: '';
  }
  &::after {
    clear: both;
  }
}
%slide-up {
  opacity: 0;
  transform: translateY(1rem);
  transition: all .8s $bounce-curve;
}
%slide-up-reveal {
  opacity: 1;
  transform: translateX(0);
}
@import "../globals/positioning";
