.sidebar {
   background: $lightgray;
   margin: 1.5rem 0;
   @include desktop {
      margin: 0 0 2rem 0;
   }
   &-header {
   	background: $primary;
   	color: $white;
   	font-family: $headline;
   	padding: .75rem 1rem;
   	text-transform: uppercase;
   	text-align: left;
   	@extend %slide-up;	
   	@extend %headline7;	
   	&.reveal {
   		@extend %slide-up-reveal;	
   	}
   	&-link {
         display: block;
         color: $white !important;
         transition: $slow;
         font-weight: 700;
   	}
   }  
   &-list {
      padding: 1rem 0;
      &-item {
         @extend %slide-up;	
         &.reveal {
            @extend %slide-up-reveal;	
         }      
      }
      &-link {
         @extend %headline6;	
         color: $secondary;
         transition: $slow;
         padding: .5rem 1rem;
         text-align: left;
         display: block !important;
         &:hover {
            background: $secondary;
            color: $white !important;
         }
         &:focus {
            background: $red;
            color: $white !important;
         }        
      }
   }
}    
