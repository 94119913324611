@import "globals/globals";
@import "pages/basic-blocks";
@import "pages/basic-text"; 
@import "pages/child-list";   
@import "pages/grids";   
@import "pages/hero";   
@import "pages/latest-feed";   
@import "pages/menu-box";   
@import "pages/meta";   
@import "pages/news-list";   
@import "pages/press";   
@import "pages/related-news";   
@import "pages/share";
@import "pages/sidebar-list";   
@import "pages/text-layer";   
@import "pages/video";   

.basic-wrap {
	text-align: left;
	margin: 0 auto;
	@extend %block-padding-small;	
	transition: $slow;
	@extend %basic-text;	
	max-width: $tablet;
}
